import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

import { BankPayloadsCollection } from '../../baas/bank-payloads/bank-payloads-collection';
import { BankPayloadsService } from '../../baas/bank-payloads/bank-payloads.service';
import { BankCustomerEntity, MtBankCustomerEntity } from '../../baas/customer/bank-customer-entity';
import { BankCustomerService } from '../../baas/customer/bank-customer.service';
import { NotifierService } from '../../notifier';
import { SigningService } from '../../signing/signing.service';
import { UserEntity } from '../user-entity';
import { UserService } from '../user.service';
import { UserApiResponse } from './user-api-response';

interface AmlDetails {
  amlResult: string;
  result: string;
  bankCustomerId: string;
  existingCustomer: string;
  mainManualNote: string;
}

interface EnrollmentContract {
  downloaded: string;
  published: string;
  signed: string;
  submitted: string;
  igniSignSignerId: string;
  igniSignDocumentId: string;
  igniSignsignatureRequestId: string;
  igniSignsignatureRequestUrl: string;
  customerMTBankAccountId: string;
}

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
})
export class DetailComponent implements OnInit {
  walletUserForm;
  user: UserEntity;
  loading: boolean;
  walletAccounts;
  userId: string;
  amlDetails: AmlDetails;
  enrollmentContract: EnrollmentContract;
  emailTemplates: { template: string; key: number }[] = [];
  selectedTemplate = '';

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private bankPayloadsService: BankPayloadsService,
    private bankCustomerService: BankCustomerService,
    private errorHandlerService: ErrorHandlerService,
    private signingService: SigningService,
    private notifier: NotifierService
  ) {
    this.loading = true;
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.walletUserForm = this.formBuilder.group({
      lastName: '',
      firstName: '',
      countryOfBirth: '',
      phoneNumber: '',
      mobileNumber: '',
      address: this.formBuilder.group({
        city: '',
        country: '',
        postCode: '',
        state: '',
        streetName: '',
        streetNumber: '',
      }),
      userKycDetails: this.formBuilder.group({
        data: [],
        kycStatus: '',
        status: '',
      }),
      language: '',
      email: '',
      status: '',
      riskProfiles: '',
      lastTransactionDate: '',
      subscriptionPlan: this.formBuilder.group({
        code: '',
      }),
      subscriptionStatus: '',
      subscriptionPaymentStatus: '',
      subscriptionBillingPeriod: '',
    });

    this.user = {
      address: {
        city: '',
        country: '',
        postCode: '',
        state: '',
        streetName: '',
        streetNumber: '',
      },
      userKycDetails: {
        data: [],
        kycStatus: '',
        status: '',
      },
      lastName: '',
      firstName: '',
      countryOfBirth: '',
      email: '',
      status: '',
      language: '',
      phoneNumber: '',
      mobileNumber: '',
      userId: '',
      createdAt: '',
      riskProfiles: 'N/A',
      subscription: {
        status: 'N/A',
        paymentStatus: 'N/A',
        billingPeriod: 'N/A',
        plan: {
          code: 'N/A',
        },
      },
    };

    this.amlDetails = {
      amlResult: 'N/A',
      result: 'N/A',
      bankCustomerId: 'N/A',
      existingCustomer: 'No',
      mainManualNote: 'N/A',
    };

    this.enrollmentContract = {
      signed: 'No',
      submitted: 'No',
      downloaded: 'No',
      published: 'No',
      igniSignSignerId: 'N/A',
      igniSignDocumentId: 'N/A',
      igniSignsignatureRequestId: 'N/A',
      igniSignsignatureRequestUrl: 'javascript:;',
      customerMTBankAccountId: 'N/A',
    };
  }

  ngOnInit(): void {
    this.loading = true;
    this.authService.token().subscribe(() => {
      this.userService
        .fetch(this.userId)
        .subscribe((response: WalletApiResponse<UserApiResponse>) => {
          this.user = response.data.walletAccountUser;
          this.walletUserForm.patchValue({
            lastName: response.data.walletAccountUser.lastName,
            firstName: response.data.walletAccountUser.firstName,
            countryOfBirth: response.data.walletAccountUser.countryOfBirth,
            email: response.data.walletAccountUser.email,
            phoneNumber: response.data.walletAccountUser.phoneNumber,
            mobileNumber: response.data.walletAccountUser.mobileNumber,
            language: response.data.walletAccountUser.language,
            status: response.data.walletAccountUser.status,
            address: response.data.walletAccountUser.address,
            userKycDetails: response.data.walletAccountUser.userKycDetails,
            riskProfiles: 'N/A',
            subscriptionPlan: response.data.walletAccountUser.subscription.plan,
            subscriptionStatus:
              response.data.walletAccountUser.subscription.status,
            subscriptionPaymentStatus:
              response.data.walletAccountUser.subscription.paymentStatus,
            subscriptionBillingPeriod:
              response.data.walletAccountUser.subscription.billingPeriod,
          });
          this.loading = false;
        });
      this.userService
        .fetchUserAccount(this.userId)
        .subscribe((response: WalletApiResponse<any>) => {
          if (response['status'] !== 'success') {
            //   this.errorHandlerService.handleGeneralError(response.StatusDescription);
            this.notifier.error(response.StatusDescription);
            this.loading = false;
            return;
          }
          const walletAccounts = response.data.walletAccounts;
          const accountId = walletAccounts.find(
            (account) => account.name == 'Main Account'
          ).accountId;
          this.userService
            .fetchUserTransactions(this.userId, accountId)
            .subscribe((resp: WalletApiResponse<any>) => {
              if (resp.data.length == 0) {
                return;
              }
              const transactions = resp.data[0].walletAccountTransactions;
              const lastTransaction = transactions.sort(
                (a, b) =>
                  new Date(b.datetime).getTime() -
                  new Date(a.datetime).getTime()
              )[0];
              const date = new Date(lastTransaction.datetime * 1000);
              this.walletUserForm.patchValue({
                lastTransactionDate: date.toLocaleString('en-US', {
                  timeZone: 'UTC',
                }),
              });
            });
        });

      let httpParams = new HttpParams().set('action', 'CreateCustomerFromEKycSession');
      this.bankPayloadsService
        .fetchAll(this.userId, httpParams)
        .subscribe((response: WalletApiResponse<BankPayloadsCollection>) => {
          let message = response.data.message;
          if (message.length > 0) {
            let responsePayload = JSON.parse(message[0].ResponsePayload);
            this.amlDetails['amlResult'] = responsePayload['amlResult'];
            this.amlDetails['result'] = responsePayload['result'];
            if ('saveOrUpdatedCustomerId' in responsePayload) {
              this.amlDetails['bankCustomerId'] =
                responsePayload['saveOrUpdatedCustomerId'];
            }
            if ('foundCustomerId' in responsePayload) {
              this.amlDetails['existingCustomer'] = 'Yes';
            }
            console.info(this.amlDetails);
          }
        });

      this.bankCustomerService
        .fetchBankUserDetails(this.userId)
        .subscribe(
          (response: WalletApiResponse<Array<MtBankCustomerEntity>>) => {
            let mtCustomer = response.data[0];

            if ('mainManualNote' in mtCustomer) {
              this.amlDetails.mainManualNote = mtCustomer.mainManualNote;
            }
          }
        );

      this.bankCustomerService
        .fetchAll(new HttpParams().set('customer_id', this.userId))
        .subscribe((response: WalletApiResponse<BankCustomerEntity>) => {
          let data = response.data;

          if ('signature_document' in data) {
            if (data.signature_document != null) {
              if ('downloaded' in data.signature_document) {
                if (data.signature_document.downloaded) {
                  this.enrollmentContract.downloaded = 'Yes';
                }
              }

              if ('documentId' in data.signature_document) {
                this.enrollmentContract.igniSignDocumentId =
                  data.signature_document.documentId;
              }
            }
          }

          if ('signature_request' in data) {
            if (data.signature_request.published) {
              this.enrollmentContract.published = 'Yes';
            }
            this.enrollmentContract.igniSignsignatureRequestId =
              data.signature_request.signatureRequestId;
            this.enrollmentContract.igniSignsignatureRequestUrl =
              'https://console.ignisign.io/orgs/650558a0ce02e4001c586efe/apps/appId_ff037c8d-5b4d-4a32-bf23-32fbc66263bb/signature-requests/' +
              data.signature_request.signatureRequestId;
          }

          if ('signer' in data) {
            if ('signerId' in data.signer) {
              this.enrollmentContract.igniSignSignerId = data.signer.signerId;
            }
          }

          if ('enrollment_data' in data) {
            if ('sent' in data.enrollment_data) {
              if (data.enrollment_data.sent) {
                this.enrollmentContract.submitted = 'Yes';
              }
            }

            if ('signed' in data.enrollment_data) {
              if (data.enrollment_data.signed) {
                this.enrollmentContract.signed = 'Yes';
              }
            }

            if ('id_customer_MT_bank_account' in data.enrollment_data) {
              this.enrollmentContract.customerMTBankAccountId =
                data.enrollment_data.id_customer_MT_bank_account;
            }
          }
        });
    });

    this.getEmailTemplates();
  }

  updateBankUserDetails(): void {
    this.loading = true;
    this.authService.token().subscribe(() => {
      this.bankCustomerService
        .updateBankUserDetails(this.userId)
        .subscribe((response: WalletApiResponse<BankCustomerEntity>) => {
          let data = response.data;
          this.loading = false;
        });
    });
  }

  publishSignatureRequest(): void {
    this.loading = true;
    this.authService.token().subscribe(() => {
      this.signingService
        .createAndPublish(this.userId)
        .subscribe((response: WalletApiResponse<{}>) => {
          this.loading = false;
        });
    });
  }

  onValidate(): void {
    this.userService
      .validateUser(this.userId)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.notifier.success('Request sent successfully.');
        },
      });
  }

  getEmailTemplates(): void {
    this.userService.fetchEmailTemplates().subscribe({
      next: (res) => {
        if (res.status === 'success') {
          this.emailTemplates = Object.keys(res.data).map((k) => ({
            template: k,
            key: res.data[k],
          }));
        }
      },
      error: console.error,
    });
  }

  sendEmail(): void {

    this.userService
      .sendEmail(this.user.email, this.selectedTemplate)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.notifier.success(res.status);
            return;
          }
        },
      });
  }
}
