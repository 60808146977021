<div class="bg-body-light">
  <div class="content content-full">
    <div
      class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
    >
      <h1 class="flex-sm-fill h3 my-2">Bank Payloads</h1>
    </div>
  </div>
</div>
<div class="col-xl-6">
  <div class="block">
    <div class="block-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb push">
          <li class="breadcrumb-item">
            <a [routerLink]="['/users']">User List</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/users', userId]">User</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Bank Payloads
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>
<div class="js-wizard-simple block">
  <div
    class="text-center"
    *ngIf="loading"
    style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="content" *ngIf="!loading">
  <div class="row">
    <div class="col-md-12">
      <div class="block">
        <div class="block-header">
          <h3 class="block-title"></h3>
          <div class="block-options">
            <div class="form-group">
              <label for="PayloadType">Payload Type</label>
              <select
                class="form-control"
                id="PayloadType"
                name="PayloadType"
                #selectList
                (change)="fetchPayload(selectList.value)"
              >
                <option value="All">Select All</option>
                <option value="CreateCustomer">Customer Creation</option>
                <option value="GetEnrollmentContract">
                  Getting Enrollment Contract
                </option>
                <option value="SendEnrollmentContract">
                  Sending Enrollment Contract
                </option>
                <option value="SendScaCode">Sending SCA Codes</option>
                <option value="CreateBankAccount">Bank account creation</option>
                <option value="GetPaymentForm">Getting Payment Form</option>
              </select>
            </div>
          </div>
        </div>
        <div class="block-content">
          <p class="font-size-sm text-muted"></p>
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-vcenter">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Timestamp</th>
                  <th style="width: 15%">Request Payload</th>
                  <th style="width: 15%">Response Payload</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let bank_event of bank_events">
                  <td class="font-size-sm">
                    <em class="text-muted">{{ bank_event.context.action }}</em>
                  </td>
                  <td class="font-size-sm">
                    <em class="text-muted">{{
                      bank_event.Timestamp * 1000
                        | date : "MMM dd, yyyy 'at' hh:mm a"
                    }}</em>
                  </td>
                  <td class="font-size-sm">
                    <textarea rows="5" cols="50" class="text-muted">{{
                      bank_event.payload | bankPayloadsPipe : "request"
                    }}</textarea>
                  </td>
                  <td class="font-size-sm">
                    <textarea rows="5" cols="50" class="text-muted">{{
                      bank_event.ResponsePayload
                    }}</textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
