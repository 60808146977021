import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';

import { environment } from '../../environments/environment';
import { WalletEventApiResponse, WalletEventParams, WalletEventsDescriptionApiResponse } from '../models';
import {
    MoneyTransEkycSessionsResponse,
    PendingValidation,
    TransactionsResponse,
    UserKycDetails,
    UserKycErrorDetails,
} from './models';
import { UserEntity } from './user-entity';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: ApiService) {}

  fetchAll(filter: HttpParams): Observable<any> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/users`,
      filter
    );
  }

  fetch(userId: string): Observable<any> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/users/${userId}`
    );
  }

  fetchByEmail(email: string): Observable<any> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/users/${email}/email`
    );
  }

  fetchByPhone(mobile: string): Observable<any> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/users/${mobile}/phone/mobile`
    );
  }

  fetchUserAccount(userId: string): Observable<any> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/users/${userId}/accounts`
    );
  }

  fetchUserTransactions(userId: string, accountId: string): Observable<any> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/users/${userId}/accounts/${accountId}/transactions`
    );
  }

  fetchBankDetails(userId: string): Observable<any> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/money-trans/customers/${userId}/smile-account`
    );
  }

  fetchDebitCardDetails(userId: string): Observable<any> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/money-trans/customers/${userId}/smile-account/debit-card`
    );
  }

  fetchUsersWithPendingKYC(filter?: HttpParams): Observable<PendingValidation> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/users/kyc/status/processing`,
      filter
    );
  }

  fetchUserErrorDetails(userId: string): Observable<UserKycErrorDetails> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/users/${userId}/kyc/check/failed`
    );
  }

  fetchKycErrorDetails(params: HttpParams): Observable<UserKycErrorDetails> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/users/kyc/check/failed`,
      params
    );
  }

  fetchWalletEvents(
    data: Partial<WalletEventParams>
  ): Observable<WalletEventApiResponse> {
    return this.apiService.post(
      `${environment.wallet.walletEventsBaseUrl}/v1/events/search`,
      data
    );
  }

  fetchWalletEventsDescription(
    desc: string
  ): Observable<WalletEventsDescriptionApiResponse> {
    return this.apiService.get(
      `${environment.wallet.bassServiceBaseUrl}/api/bank-events/descriptions/list?query=${desc}`
    );
  }

  validateUser(userId: string): Observable<UserKycDetails> {
    return this.apiService.patch(
      `${environment.wallet.complianceServiceBaseUrl}/v1/compliance/documents/users/${userId}/validate/kyc/documents/status`,
      {}
    );
  }

  create(userEntity: UserEntity): Observable<any> {
    return this.apiService.post(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/users`,
      userEntity
    );
  }

  uploadTransactions(data: FormData): Observable<TransactionsResponse> {
    return this.apiService.post(
      `${environment.wallet.bassServiceBaseUrl}/api/transactions/csv`,
      data
    );
  }

  fetchEmailTemplates(): Observable<{
    status: string;
    data: Record<string, number>;
  }> {
    return this.apiService.get(
      `${environment.wallet.walletGwBaseUrl}/v1/notifications/emails/templates`
    );
  }

  sendEmail(email: string, templateName: string): Observable<any> {
    return this.apiService.post(
      `${environment.wallet.walletGwBaseUrl}/v1/notifications/emails/send`,
      { email, templateName }
    );
  }

  fetchEkycIdentities(
    params: HttpParams
  ): Observable<MoneyTransEkycSessionsResponse> {
    return this.apiService.get(
      `${environment.wallet.bassServiceBaseUrl}/api/e-kyc/identities`,
      params
    );
  }

  fetchSessionsStatuses(): Observable<string[]> {
    return this.apiService
      .get(
        `${environment.wallet.bassServiceBaseUrl}/api/e-kyc/sessions/statuses`
      )
      .pipe(map((res) => res.data));
  }

  updateEkycStatus(sessionId: string, session_status: string): Observable<any> {
    return this.apiService.patch(
      `${environment.wallet.bassServiceBaseUrl}/api/e-kyc/identities/${sessionId}/${session_status}`,
      null
    );
  }
}
