import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ApiService} from 'src/app/api.service';
import {BankPayloadsEntity} from "./bank-payloads-entity";

@Injectable({
  providedIn: 'root'
})
export class BankPayloadsService {
  constructor(
    private apiService: ApiService
  ) {
  }

  fetchAll(
    userId: string,
    httpParams: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.apiService.get(
      environment.wallet.bassServiceBaseUrl + '/api/bank-events/' + userId, httpParams);
  }
}
