import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { UserDocumentApiResponse } from 'src/app/compliance-api/documents/user-document-api-response';
import { UserDocumentCollection } from 'src/app/compliance-api/documents/user-document-collection';
import { UserDocumentEntity } from 'src/app/compliance-api/documents/user-document-entity';
import { UserDocumentsService } from 'src/app/compliance-api/documents/user-documents.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

import { BankCustomerService } from '../../../baas/customer/bank-customer.service';

@Component({
  selector: 'app-edit-user-document',
  templateUrl: './edit-user-document.component.html',
  styleUrls: ['./edit-user-document.component.css'],
})
export class EditUserDocumentComponent implements OnInit {
  updateDocumentStatusForm;
  updateMetadataForm;
  sendDocumentToMtForm;
  alert: AlertEntity;
  inProgress: boolean;
  userId: string;
  documentId: string;
  loading: boolean;
  versions: Array<UserDocumentEntity>;
  document: UserDocumentEntity;
  sendDocumentToMtInProgress: boolean;
  documentFile: string = '';
  isImage = false;
  pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  src: Uint8Array;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private userDocumentService: UserDocumentsService,
    private bankCustomerService: BankCustomerService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.documentId = this.route.snapshot.paramMap.get('documentId');

    this.updateDocumentStatusForm = this.formBuilder.group({
      status: [null, Validators.required],
      comment: [null, Validators.required],
    });
    this.updateMetadataForm = this.formBuilder.group({
      documentNumber: [null, Validators.required],
      dateOfIssue: [null, Validators.required],
      expiryDate: [null, Validators.required],
    });

    this.sendDocumentToMtForm = this.formBuilder.group({
      documentType: [null, Validators.required],
    });

    this.alert = {
      display: false,
      message: '',
    };
  }

  ngOnInit(): void {
    window.onload = (event) => {
      window.One.layout('header_loader_on');
    };
    this.loading = true;
    this.authService.token().subscribe(() => {
      this.userDocumentService
        .fetch(this.userId, this.documentId)
        .subscribe((response: WalletApiResponse<UserDocumentApiResponse>) => {
          this.document = response.data.Document;
          this.updateDocumentStatusForm.patchValue({
            status: this.document.status,
          });

          if ('metadata' in this.document) {
            this.updateMetadataForm.patchValue({
              documentNumber:
                'documentNumber' in this.document.metadata
                  ? this.document.metadata.documentNumber
                  : '',
              dateOfIssue:
                'dateOfIssue' in this.document.metadata
                  ? this.getDateFromTimestamp(
                      this.document.metadata.dateOfIssue
                    )
                  : '',
              expiryDate:
                'expiryDate' in this.document.metadata
                  ? this.getDateFromTimestamp(this.document.metadata.expiryDate)
                  : '',
            });
          }

          if (this.document.fileMimeType && this.document.content) {
            this.isImage = !this.document.fileExtension
              .toLowerCase()
              .includes('pdf');

            if (this.isImage) {
              this.documentFile =
                'data:' +
                this.document.fileMimeType +
                ';base64,' +
                this.document.content;
            } else {
              this.src = this.base64ToUInt8Array(this.document.content);
            }
          }

          window.One.layout('header_loader_off');
          this.userDocumentService
            .fetchAll(
              this.userId,
              new HttpParams().set('type', this.document.type.toString())
            )
            .subscribe(
              (response: WalletApiResponse<UserDocumentCollection>) => {
                this.versions = response.data.Documents;
                this.loading = false;
              }
            );
        });
    });
  }

  base64ToUInt8Array(base64) {
    // Decode Base64 string to binary string
    let binaryString = atob(base64);

    // Create a UInt8Array with the length of the binary string
    let len = binaryString.length;
    let bytes = new Uint8Array(len);

    // Fill the UInt8Array with the binary data
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  }

  updateDocumentStatus() {
    this.inProgress = true;
    this.authService.token().subscribe((response) => {
      this.authService.user().subscribe((user) => {
        this.userDocumentService
          .changeStatus(
            this.userId,
            this.documentId,
            this.updateDocumentStatusForm.get('status').value,
            '[' +
              user.userName +
              '] ' +
              this.updateDocumentStatusForm.get('comment').value
          )
          .subscribe((response) => {
            this.document = response.data.Document;
            this.inProgress = false;
            this.alert.display = true;
            this.alert.message = 'Successfully changed document status';
          });
      });
    });
  }

  sendDocumentToMt() {
    this.sendDocumentToMtInProgress = true;
    this.authService.token().subscribe((response) => {
      this.authService.user().subscribe((user) => {
        this.bankCustomerService
          .attachDocument(this.userId, this.document.type)
          .subscribe((response) => {
            this.sendDocumentToMtInProgress = false;
            this.alert.display = true;
            this.alert.message = 'Successfully sent document to MoneyTrans';
          });
      });
    });
  }

  downloadDocument() {
    const a = document.createElement('a');
    a.href =
      'data:' + this.document.fileMimeType + ';base64,' + this.document.content;
    a.download = this.document.fileName;
    a.click();
  }

  updateMetadata() {
    this.inProgress = true;

    const metadata = {
      documentNumber: this.updateMetadataForm.get('documentNumber').value,
      dateOfIssue: this.getTimestampFromDate(
        this.updateMetadataForm.get('dateOfIssue').value
      ),
      expiryDate: this.getTimestampFromDate(
        this.updateMetadataForm.get('expiryDate').value
      ),
    };

    this.authService.token().subscribe((response) => {
      this.authService.user().subscribe((user) => {
        this.userDocumentService
          .attachMetadata(this.userId, this.documentId, metadata)
          .subscribe((response) => {
            this.document = response.data.Document;
            this.inProgress = false;
            this.alert.display = true;
            this.alert.message = 'Successfully attached metadata';
          });
      });
    });
  }

  getDateFromTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-UK');
  }

  getTimestampFromDate(date) {
    date = date.split('/');
    date = new Date(date[2], date[1] - 1, date[0]);
    const timestamp = new Date(date).getTime();
    return timestamp / 1000;
  }
}
