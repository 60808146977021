<div id="page-container"
     [ngClass]="{'sidebar-o sidebar-dark enable-page-overlay side-scroll page-header-fixed':isLoggedIn}">
    <!-- Side Overlay-->
    <aside id="side-overlay"
           class="font-size-sm">
        <!-- Side Header -->
        <div class="content-header border-bottom">
            <!-- User Avatar -->
            <a class="img-link mr-1"
               href="javascript:void(0)">
                <img class="img-avatar img-avatar32"
                     src="../assets/oneui/media/avatars/avatar10.jpg"
                     alt="">
            </a>
            <!-- END User Avatar -->

            <!-- User Info -->
            <div class="ml-2">
                <a class="link-fx text-dark font-w600"
                   href="javascript:void(0)">Adam McCoy</a>
            </div>
            <!-- END User Info -->

        </div>
        <!-- END Side Header -->
    </aside>
    <!-- END Side Overlay -->

    <!-- Sidebar -->
    <!--
      Sidebar Mini Mode - Display Helper classes

      Adding 'smini-hide' class to an element will make it invisible (opacity: 0) when the sidebar is in mini mode
      Adding 'smini-show' class to an element will make it visible (opacity: 1) when the sidebar is in mini mode
          If you would like to disable the transition animation, make sure to also add the 'no-transition' class to your element

      Adding 'smini-hidden' to an element will hide it when the sidebar is in mini mode
      Adding 'smini-visible' to an element will show it (display: inline-block) only when the sidebar is in mini mode
      Adding 'smini-visible-block' to an element will show it (display: block) only when the sidebar is in mini mode
  -->
    <nav id="sidebar"
         *ngIf="isLoggedIn"
         aria-label="Main Navigation">
        <!-- Side Header -->
        <div class="content-header">
            <!-- Logo -->
            <a href="#">
                <span class="">
                    <img class="rounded"
                         src="../assets/nbk_logo.jpg"
                         alt="Header Avatar"
                         style="width: 130px;">
                </span>
            </a>
            <!-- END Logo -->

            <!-- Extra -->
            <div>
                <!-- Close Sidebar, Visible only on mobile screens -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
                <a class="d-lg-none btn btn-sm btn-dual ml-2"
                   data-toggle="layout"
                   data-action="sidebar_close"
                   href="javascript:void(0)">
                    <i class="fa fa-fw fa-times"></i>
                </a>
                <!-- END Close Sidebar -->
            </div>
            <!-- END Extra -->
        </div>
        <!-- END Side Header -->

        <!-- Side Navigation -->
        <div class="content-side content-side-full">
            <ul class="nav-main">
                <!-- <li class="nav-main-item">
                    <a class="nav-main-link"
                       routerLinkActive="active"
                       href="#">
                        <i class="nav-main-link-icon si si-speedometer"></i>
                        <span class="nav-main-link-name">Dashboard</span>
                    </a>
                </li> -->
                <li class="nav-main-item">
                    <a [routerLink]="['/dashboard']"
                       routerLinkActive="active"
                       class="nav-main-link nav-main-link-submenu"
                       data-toggle="submenu"
                       aria-haspopup="true"
                       aria-expanded="false"
                       href="#">
                        <i class="nav-main-link-icon si si-speedometer"></i>
                        <span class="nav-main-link-name">Dashboard</span>
                    </a>
                </li>
                <li class="nav-main-heading">Wallet Management</li>
                <li class="nav-main-item">
                    <a [routerLink]="['/organizations']"
                       routerLinkActive="active"
                       class="nav-main-link nav-main-link-submenu"
                       data-toggle="submenu"
                       aria-haspopup="true"
                       aria-expanded="false"
                       href="#">
                        <i class="nav-main-link-icon si si-globe"></i>
                        <span class="nav-main-link-name">Organizations</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a [routerLink]="['/plans']"
                       class="nav-main-link nav-main-link-submenu"
                       data-toggle="submenu"
                       aria-haspopup="true"
                       aria-expanded="false"
                       href="#">
                        <i class="nav-main-link-icon si si-folder"></i>
                        <span class="nav-main-link-name">Wallet Plans</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a [routerLink]="['/users']"
                       class="nav-main-link nav-main-link-submenu"
                       routerLinkActive="active"
                       data-toggle="submenu"
                       aria-haspopup="true"
                       aria-expanded="false"
                       href="#">
                        <i class="nav-main-link-icon si si-users"></i>
                        <span class="nav-main-link-name active">Users</span>
                    </a>
                    <ul class="nav-main-submenu">
                        <li class="nav-main-item">
                            <a class="nav-main-link"
                               routerLinkActive="active"
                               [routerLink]="['/users', 'validation', 'pending']"
                               href="#">
                                <span class="nav-main-link-name">Pending Validation</span>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav-main-submenu">
                        <li class="nav-main-item">
                            <a class="nav-main-link"
                               routerLinkActive="active"
                               [routerLink]="['/users', 'kyc', 'errors']"
                               href="#">
                                <span class="nav-main-link-name">KYC Errors</span>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav-main-submenu">
                        <li class="nav-main-item">
                            <a class="nav-main-link"
                               routerLinkActive="active"
                               [routerLink]="['/users', 'transactions']"
                               href="#">
                                <span class="nav-main-link-name">Transactions</span>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav-main-submenu">
                        <li class="nav-main-item">
                            <a class="nav-main-link"
                               routerLinkActive="active"
                               [routerLink]="['/users', 'moneytrans', 'ekyc']"
                               href="#">
                                <span class="nav-main-link-name">MoneyTrans EKYC Sessions</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-main-item">
                    <a [routerLink]="['/events']"
                       class="nav-main-link nav-main-link-submenu"
                       routerLinkActive="active"
                       data-toggle="submenu"
                       aria-haspopup="true"
                       aria-expanded="false"
                       href="#">
                        <i class="nav-main-link-icon si si-calendar"></i>
                        <span class="nav-main-link-name">Events</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a [routerLink]="['/wallet-events']"
                       class="nav-main-link nav-main-link-submenu"
                       routerLinkActive="active"
                       data-toggle="submenu"
                       aria-haspopup="true"
                       aria-expanded="false"
                       href="#">
                        <i class="nav-main-link-icon si si-folder"></i>
                        <span class="nav-main-link-name">Wallet Events</span>
                    </a>
                </li>
                <li class="nav-main-heading">Compliance</li>
                <li class="nav-main-item">
                    <a class="nav-main-link nav-main-link-submenu"
                       data-toggle="submenu"
                       routerLinkActive="active"
                       aria-haspopup="true"
                       aria-expanded="false"
                       href="#">
                        <i class="nav-main-link-icon si si-wallet"></i>
                        <span class="nav-main-link-name">KYC</span>
                    </a>
                    <ul class="nav-main-submenu">
                        <li class="nav-main-item">
                            <a class="nav-main-link nav-main-link-submenu"
                               data-toggle="submenu"
                               routerLinkActive="active"
                               aria-haspopup="true"
                               aria-expanded="false"
                               href="#">
                                <span class="nav-main-link-name">Rules</span>
                            </a>
                            <ul class="nav-main-submenu">
                                <li class="nav-main-item">
                                    <a class="nav-main-link nav-main-link-submenu"
                                       data-toggle="submenu"
                                       routerLinkActive="active"
                                       aria-haspopup="true"
                                       aria-expanded="false"
                                       href="#">
                                        <span class="nav-main-link-name">Transactions</span>
                                    </a>
                                    <ul class="nav-main-submenu">
                                        <li class="nav-main-item">
                                            <a class="nav-main-link"
                                               routerLinkActive="active"
                                               [routerLink]="['/compliance','kyc', 'rules', 'transactions', 'amount', 'list']"
                                               href="#">
                                                <span class="nav-main-link-name">Amount</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </li>
                        <li class="nav-main-item">
                            <a class="nav-main-link"
                               routerLinkActive="active"
                               [routerLink]="['/compliance','kyc', 'regions', 'list']"
                               href="#">
                                <span class="nav-main-link-name">Regions</span>
                            </a>
                        </li>

                        <li class="nav-main-item">
                            <a class="nav-main-link"
                               routerLinkActive="active"
                               [routerLink]="['/compliance','documents', 'types', 'list']"
                               href="#">
                                <span class="nav-main-link-name">Document Types</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-main-heading">Tontines Management</li>
                <li class="nav-main-item">
                    <a class="nav-main-link nav-main-link-submenu"
                       routerLinkActive="active"
                       [routerLink]="['/tontines', 'list']"
                       data-toggle="submenu"
                       aria-haspopup="true"
                       aria-expanded="false"
                       href="#">
                        <i class="nav-main-link-icon fa fa-users"></i>
                        <span class="nav-main-link-name">Tontines</span>
                    </a>
                    <ul class="nav-main-submenu">
                        <li class="nav-main-item">
                            <a class="nav-main-link"
                               routerLinkActive="active"
                               [routerLink]="['/collections', 'list']"
                               href="#">
                                <span class="nav-main-link-name">Collections</span>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav-main-submenu">
                        <li class="nav-main-item">
                            <a class="nav-main-link"
                               routerLinkActive="active"
                               [routerLink]="['/distributions', 'list']"
                               href="#">
                                <span class="nav-main-link-name">Distributions</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-main-item">
                    <a [routerLink]="['/chats/list']"
                       class="nav-main-link nav-main-link-submenu"
                       routerLinkActive="active"
                       data-toggle="submenu"
                       aria-haspopup="true"
                       aria-expanded="false"
                       href="#">
                        <i class="nav-main-link-icon fa fa-comments"></i>
                        <span class="nav-main-link-name">Chats</span>
                    </a>
                </li>
                <li class="nav-main-heading">Fees Management</li>
                <li class="nav-main-item">
                    <a class="nav-main-link"
                       [routerLink]="['/fees', 'list']"
                       routerLinkActive="active"
                       href="#">
                        <i class="nav-main-link-icon fa fa-money-bill"></i>
                        <span class="nav-main-link-name">Fees</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a [routerLink]="['/service-status']"
                       routerLinkActive="active"
                       class="nav-main-link nav-main-link-submenu"
                       data-toggle="submenu"
                       aria-haspopup="true"
                       aria-expanded="false"
                       href="#">
                        <i class="nav-main-link-icon fa fa-server"></i>
                        <span class="nav-main-link-name">Service Statuses</span>
                    </a>
                </li>
                <li class="nav-main-heading">Reports</li>

                <li class="nav-main-heading">Fraud Detection</li>

                <li class="nav-main-heading" style="cursor: pointer;" [routerLink]="['/support-cases', 'list']">
                    Support Cases
                </li>
            </ul>
        </div>
        <!-- END Side Navigation -->
    </nav>
    <!-- END Sidebar -->

    <!-- Header -->
    <header id="page-header"
            *ngIf="isLoggedIn">

        <!-- Header Content -->
        <div class="content-header">
            <!-- Left Section -->
            <div class="d-flex align-items-center">
                <!-- Toggle Sidebar -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
                <button type="button"
                        class="btn btn-sm btn-dual mr-2 d-lg-none"
                        data-toggle="layout"
                        data-action="sidebar_toggle">
                    <i class="fa fa-fw fa-bars"></i>
                </button>
                <!-- END Toggle Sidebar -->

                <!-- Toggle Mini Sidebar -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
                <button type="button"
                        class="btn btn-sm btn-dual mr-2 d-none d-lg-inline-block"
                        data-toggle="layout"
                        data-action="sidebar_mini_toggle">
                    <i class="fa fa-fw fa-ellipsis-v"></i>
                </button>
                <!-- END Toggle Mini Sidebar -->

                <!-- Open Search Section (visible on smaller screens) -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
                <button type="button"
                        class="btn btn-sm btn-dual d-sm-none"
                        data-toggle="layout"
                        data-action="header_search_on">
                    <i class="si si-magnifier"></i>
                </button>
                <!-- END Open Search Section -->
            </div>
            <!-- END Left Section -->

            <!-- Right Section -->
            <div class="d-flex align-items-center">
                <!-- User Dropdown -->
                <div class="dropdown d-inline-block ml-2">
                    <button type="button"
                            class="btn btn-sm btn-dual"
                            id="page-header-user-dropdown"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                        <img class="rounded"
                             src="../assets/oneui/media/avatars/avatar10.jpg"
                             alt="Header Avatar"
                             style="width: 18px;">
                        <span class="d-none d-sm-inline-block ml-1">{{ user?.username }}</span>
                        <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
                         aria-labelledby="page-header-user-dropdown">
                        <div class="p-3 text-center bg-primary">
                            <img class="img-avatar img-avatar48 img-avatar-thumb"
                                 src="../assets/oneui/media/avatars/avatar10.jpg"
                                 alt="">
                        </div>
                        <div class="p-2">
                            <h5 class="dropdown-header text-uppercase">User Options</h5>
                            <a class="dropdown-item d-flex align-items-center justify-content-between"
                               href="be_pages_generic_inbox.html">
                                <span>Inbox</span>
                                <span>
                                    <span class="badge badge-pill badge-primary">3</span>
                                    <i class="si si-envelope-open ml-1"></i>
                                </span>
                            </a>
                            <a class="dropdown-item d-flex align-items-center justify-content-between"
                               href="be_pages_generic_profile.html">
                                <span>Profile</span>
                                <span>
                                    <span class="badge badge-pill badge-success">1</span>
                                    <i class="si si-user ml-1"></i>
                                </span>
                            </a>
                            <a class="dropdown-item d-flex align-items-center justify-content-between"
                               href="javascript:void(0)">
                                <span>Settings</span>
                                <i class="si si-settings"></i>
                            </a>
                            <div role="separator"
                                 class="dropdown-divider"></div>
                            <h5 class="dropdown-header text-uppercase">Actions</h5>
                            <a class="dropdown-item d-flex align-items-center justify-content-between"
                               href="op_auth_lock.html">
                                <span>Lock Account</span>
                                <i class="si si-lock ml-1"></i>
                            </a>
                            <a class="dropdown-item d-flex align-items-center justify-content-between"
                               href="#"
                               (click)="onClickLogout()">
                                <span>Log Out</span>
                                <i class="si si-logout ml-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Right Section -->
        </div>
        <!-- END Header Content -->

        <!-- Header Search -->
        <div id="page-header-search"
             class="overlay-header bg-white">
            <div class="content-header">
                <form class="w-100"
                      action="be_pages_generic_search.html"
                      method="POST">
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
                            <button type="button"
                                    class="btn btn-danger"
                                    data-toggle="layout"
                                    data-action="header_search_off">
                                <i class="fa fa-fw fa-times-circle"></i>
                            </button>
                        </div>
                        <input type="text"
                               class="form-control"
                               placeholder="Search or hit ESC.."
                               id="page-header-search-input"
                               name="page-header-search-input">
                    </div>
                </form>
            </div>
        </div>
        <!-- END Header Search -->

        <!-- Header Loader -->
        <!-- Please check out the Loaders page under Components category to see examples of showing/hiding it -->
        <div id="page-header-loader"
             class="overlay-header bg-white">
            <div class="content-header">
                <div class="w-100 text-center">
                    <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                </div>
            </div>
        </div>
        <!-- END Header Loader -->
    </header>
    <!-- END Header -->

    <!-- Main Container -->
    <main id="main-container">
        <div id="page-loader"
             class="hide"></div>
        <router-outlet></router-outlet>
    </main>
    <!-- END Main Container -->

    <!-- Footer -->
    <footer id="page-footer"
            *ngIf="false"
            class="bg-body-light">
        <div class="content py-3">
            <div class="row font-size-sm">
                <div class="col-sm-6 order-sm-2 py-1 text-center text-sm-right">
                    Crafted with <i class="fa fa-heart text-danger"></i> by <a class="font-w600"
                       href="https://1.envato.market/ydb"
                       target="_blank">pixelcave</a>
                </div>
                <div class="col-sm-6 order-sm-1 py-1 text-center text-sm-left">
                    <a class="font-w600"
                       href="https://1.envato.market/AVD6j"
                       target="_blank">OneUI 4.5</a> &copy; <span data-toggle="year-copy"></span>
                </div>
            </div>
        </div>
    </footer>
    <!-- END Footer -->

    <!-- Apps Modal -->
    <!-- Opens from the modal toggle button in the header -->
    <div class="modal fade"
         *ngIf="false"
         id="one-modal-apps"
         tabindex="-1"
         role="dialog"
         aria-labelledby="one-modal-apps"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-top modal-sm"
             role="document">
            <div class="modal-content">
                <div class="block block-themed block-transparent mb-0">
                    <div class="block-header bg-primary-dark">
                        <h3 class="block-title">Apps</h3>
                        <div class="block-options">
                            <button type="button"
                                    class="btn-block-option"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                <i class="si si-close"></i>
                            </button>
                        </div>
                    </div>
                    <div class="block-content block-content-full">
                        <div class="row gutters-tiny">
                            <div class="col-6">
                                <!-- CRM -->
                                <a class="block block-rounded block-themed bg-default"
                                   href="javascript:void(0)">
                                    <div class="block-content text-center">
                                        <i class="si si-speedometer fa-2x text-white-75"></i>
                                        <p class="font-w600 font-size-sm text-white mt-2 mb-3">
                                            CRM
                                        </p>
                                    </div>
                                </a>
                                <!-- END CRM -->
                            </div>
                            <div class="col-6">
                                <!-- Products -->
                                <a class="block block-rounded block-themed bg-danger"
                                   href="javascript:void(0)">
                                    <div class="block-content text-center">
                                        <i class="si si-rocket fa-2x text-white-75"></i>
                                        <p class="font-w600 font-size-sm text-white mt-2 mb-3">
                                            Products
                                        </p>
                                    </div>
                                </a>
                                <!-- END Products -->
                            </div>
                            <div class="col-6">
                                <!-- Sales -->
                                <a class="block block-rounded block-themed bg-success mb-0"
                                   href="javascript:void(0)">
                                    <div class="block-content text-center">
                                        <i class="si si-plane fa-2x text-white-75"></i>
                                        <p class="font-w600 font-size-sm text-white mt-2 mb-3">
                                            Sales
                                        </p>
                                    </div>
                                </a>
                                <!-- END Sales -->
                            </div>
                            <div class="col-6">
                                <!-- Payments -->
                                <a class="block block-rounded block-themed bg-warning mb-0"
                                   href="javascript:void(0)">
                                    <div class="block-content text-center">
                                        <i class="si si-wallet fa-2x text-white-75"></i>
                                        <p class="font-w600 font-size-sm text-white mt-2 mb-3">
                                            Payments
                                        </p>
                                    </div>
                                </a>
                                <!-- END Payments -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END Apps Modal -->
</div>
<!-- END Page Container -->

<ngx-sonner-toaster richColors
                    [expand]="true"
                    [position]="'top-center'"></ngx-sonner-toaster>