import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/auth.guard';
import { BankAccountCashOutComponent } from './account/cash-out/bank-account-cash-out/bank-account-cash-out.component';
import { BankCardCashOutComponent } from './account/cash-out/bank-card-cash-out/bank-card-cash-out.component';
import { MtnCashOutComponent } from './account/cash-out/mtn/mtn-cash-out/mtn-cash-out.component';
import { CreateTransferComponent } from './account/create-transfer/create-transfer.component';
import { CreateUserAccountComponent } from './account/create-user-account/create-user-account.component';
import { EditAccountComponent } from './account/edit-account/edit-account.component';
import { ListAccountComponent } from './account/list-account/list-account.component';
import { MtnComponent } from './account/topup-account/mtn/mtn.component';
import { PaypalCashInComponent } from './account/topup-account/paypal/paypal-cash-in/paypal-cash-in.component';
import { TopupAccountComponent } from './account/topup-account/topup-account.component';
import { TransactionFeesComponent } from './account/transaction-fees/transaction-fees.component';
import { TransactionsAccountComponent } from './account/transactions-account/transactions-account.component';
import { TransfersComponent } from './account/transfers/transfers.component';
import { BankDetailsComponent } from './bank-details/bank-details.component';
import { BankPayloadsComponent } from './bank-payloads/bank-payloads.component';
import {
    CreateNewUserBeneficiaryComponent,
} from './beneficiaries/create-new-user-beneficiary/create-new-user-beneficiary.component';
import { DeleteUserBeneficiaryComponent } from './beneficiaries/delete-user-beneficiary/delete-user-beneficiary.component';
import { DetailUserBeneficiaryComponent } from './beneficiaries/detail-user-beneficiary/detail-user-beneficiary.component';
import { ListUserBeneficiariesComponent } from './beneficiaries/list-user-beneficiaries/list-user-beneficiaries.component';
import { CreateUserPaymentMeanComponent } from './create-user-payment-mean/create-user-payment-mean.component';
import { CreateWalletUserComponent } from './create-wallet-user/create-wallet-user.component';
import { DetailComponent } from './detail/detail.component';
import { AddUserDocumentsComponent } from './documents/add-user-documents/add-user-documents.component';
import { EditUserDocumentComponent } from './documents/edit-user-document/edit-user-document.component';
import { ListUserDocumentsComponent } from './documents/list-user-documents/list-user-documents.component';
import { EditUserPaymentMeanComponent } from './edit-user-payment-mean/edit-user-payment-mean.component';
import { KycErrorDetailsComponent } from './kyc-error-details/kyc-error-details.component';
import { ListComponent } from './list/list.component';
import { MoneyTransEKYCErrorsComponent } from './money-trans-ekyc-errors/money-trans-ekyc-errors.component';
import { PendingValidationListComponent } from './pending-validation-list/pending-validation-list.component';
import { SupportCasesComponent } from './support-cases/support-cases/support-cases.component';
import { ViewPageComponent } from './support-cases/view-page/view-page.component';
import { ListUserTontinesComponent } from './tontine/list-user-tontines/list-user-tontines.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { UserPaymentMeansComponent } from './user-payment-means/user-payment-means.component';
import { WalletEventsComponent } from './wallet-events/wallet-events.component';

const routes: Routes = [
  {
    path: 'users',
    component: ListComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/validation/pending',
    component: PendingValidationListComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/transactions',
    component: TransactionsComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/kyc/errors',
    component: KycErrorDetailsComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/moneytrans/ekyc',
    component: MoneyTransEKYCErrorsComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId',
    component: DetailComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/payment-means',
    component: UserPaymentMeansComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/payment-means/:paymentMeanId',
    component: EditUserPaymentMeanComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/payment-means/add/new',
    component: CreateUserPaymentMeanComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts',
    component: ListAccountComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts-new',
    component: CreateUserAccountComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts/:accountId/topUp',
    component: TopupAccountComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts/:accountId/topUp/mtn',
    component: MtnComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts/:accountId/cashOut/mtn',
    component: MtnCashOutComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts/:accountId',
    component: EditAccountComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts/:accountId/transactions',
    component: TransactionsAccountComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts/:accountId/transactions/:transactionId',
    component: TransactionFeesComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/add/new',
    component: CreateWalletUserComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/transfers',
    component: TransfersComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/transfers/add/new',
    component: CreateTransferComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/beneficiaries',
    component: ListUserBeneficiariesComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/beneficiaries/add/new',
    component: CreateNewUserBeneficiaryComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/beneficiaries/:beneficiaryId',
    component: DetailUserBeneficiaryComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/beneficiaries/:beneficiaryId/delete',
    component: DeleteUserBeneficiaryComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts/:accountId/cash-out/bank-account',
    component: BankAccountCashOutComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts/:accountId/cash-out/bank-card',
    component: BankCardCashOutComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/accounts/:accountId/topUp/paypal',
    component: PaypalCashInComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/bank-details',
    component: BankDetailsComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/documents',
    component: ListUserDocumentsComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/documents/add/new',
    component: AddUserDocumentsComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/documents/:documentId',
    component: EditUserDocumentComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/tontines',
    component: ListUserTontinesComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/bank-payloads',
    component: BankPayloadsComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/wallet-events',
    component: WalletEventsComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/support-cases',
    component: SupportCasesComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'users/:userId/support-cases/:taskId',
    component: ViewPageComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
