import {Component, OnInit} from '@angular/core';

import {AuthService} from 'src/app/auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import {WalletApiResponse} from 'src/app/wallet-api/wallet-api-response';
import {BankPayloadsService} from "../../baas/bank-payloads/bank-payloads.service";
import {BankPayloadsCollection} from "../../baas/bank-payloads/bank-payloads-collection";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'app-bank-payloads',
  templateUrl: './bank-payloads.component.html',
  styleUrls: ['./bank-payloads.component.css'],
})
export class BankPayloadsComponent implements OnInit {

  bank_events;
  loading: boolean;
  userId: string;

  constructor(
    private bankPayloadsService: BankPayloadsService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.loading = true;
  }

  ngOnInit(): void {
    this.loading = true;
    this.authService.token().subscribe(() => {
      this.bankPayloadsService.fetchAll(this.userId).subscribe((response: WalletApiResponse<BankPayloadsCollection>) => {
        this.bank_events = response.data.message
        this.loading = false;
      })
    })
  }

  fetchPayload(value): void {
    this.loading = true;
    this.authService.token().subscribe(() => {
      let httpParams = new HttpParams()
      if(value != 'All'){
        httpParams = httpParams.set('action',value)
      }
      this.bankPayloadsService.fetchAll(this.userId, httpParams ).subscribe((response: WalletApiResponse<BankPayloadsCollection>) => {
        this.bank_events = response.data.message
        this.loading = false;
      })
    })
  }
}
